$blue: #066580;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #dc3545;
$orange: #f8af32;
$yellow: #f8ac00;
$green: #3b7851;
$teal: #789520;
$cyan: #9fc9b0;
$white: #fff;
$gray: #6c6f70;
$gray-dark: rgb(94, 95, 95);
$primary: #f8ac00;
$secondary: #4e4a47;
$success: #789520;
$info: #f4ccc2;
$warning: #ffc107;
$danger: #da3678;
$light: rgb(224, 224, 224);
$dark: rgb(38, 34, 39);
$dark-rgb: 38,
34,
39;
$background: rgb(250, 250, 250);
$background-dark-contrast: rgb(61, 61, 61);
$background-light-contrast: rgb(255, 255, 255);
$font-family-sans-serif: -apple-system,
BlinkMacSystemFont,
"Segoe UI",
Roboto,
"Helvetica Neue",
Arial,
sans-serif,
"Apple Color Emoji",
"Segoe UI Emoji",
"Segoe UI Symbol";
$font-family-monospace: SFMono-Regular,
Menlo,
Monaco,
Consolas,
"Liberation Mono",
"Courier New",
monospace;

:root {
  --blue: #{$blue};
  --indigo: #{$indigo};
  --purple: #{$purple};
  --pink: #{$pink};
  --red: #{$red};
  --orange: #{$orange};
  --yellow: #{$yellow};
  --green: #{$green};
  --teal: #{$teal};
  --cyan: #{$cyan};
  --white: #{$white};
  --gray: #{$gray};
  --gray-dark: #{$gray-dark};
  --primary: #{$primary};
  --secondary: #{$secondary};
  --success: #{$success};
  --info: #{$info};
  --warning: #{$warning};
  --danger: #{$danger};
  --light: #{$light};
  --dark: #{$dark};
  --dark-rgb: #{$dark-rgb};
  --background: #{$background};
  --background-dark-contrast: rgb(61, 61, 61);
  --background-light-contrast: rgb(255, 255, 255);
  --font-family-sans-serif: #{$font-family-sans-serif};
  --font-family-monospace: #{$font-family-monospace};

  --color-facebook: #3b5998;
  --color-google: #ea4335;

  --padding: 16px;

  // Sizes and breakpoints
  --header-heigth: 64px;
  --menu-width: 250px;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;

  // SHADOWS
  --shadows-shadow-0: 0 8px 35px rgba(29, 29, 31, 0.1);
  --shadows-shadow-1: 0 5px 7px 0 rgba(0, 0, 0, 0.1);
  --shadows-shadow-2: 0 3px 6px rgba(0, 0, 0, 0.06), 0 3px 6px rgba(0, 0, 0, 0.1);
  --shadows-shadow-3: 0 10px 20px rgba(0, 0, 0, 0.03), 0 6px 6px rgba(0, 0, 0, 0.1);
  --shadows-shadow-4: 30px 24px 36px rgba(0, 0, 0, 0.007), 23px 24px 30px rgba(0, 0, 0, 0.04);
  --shadows-shadow-5: 30px 24px 46px rgba(0, 0, 0, 0.007), 23px 24px 40px rgba(0, 0, 0, 0.08);
}
